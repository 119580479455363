import React, { useState, useEffect } from 'react';
import '../Skins.css';
import { Link } from 'react-router-dom';

function Resha() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    document.title = "resha! skinhub";
  }, []);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div>
      <div className='skins-bg'>
        <h1 className='skins-title'>
          <a className='skins-link' href='https://osu.ppy.sh/users/16866418/osu'>
          resha <img className='avatar' src='https://a.ppy.sh/16866418?1738341193.jpeg' alt='avatar'></img>
          </a>
          <button 
            className={`toggle-button ${isVisible ? 'rotate' : ''}`} 
            onClick={toggleVisibility}
          >
            ↓
          </button>
        </h1>
        <div className={`skins-container ${isVisible ? 'expanded' : ''}`}>
        <div className='skins'>
                <div className='elohere_be'>
                    <a href='https://www.mediafire.com/file/7zdcbls0hlt7jzo/hoshizora.osk/file'>
                    <img className='elohere-gif'src='https://be.s-ul.eu/sIh4QSur'></img>
                    <p className='elohere-title'>hoshizora</p> 
                    </a>
                </div>
                <div className='elohere_be'>
                    <a href='https://www.mediafire.com/file/on55eybdqd4x37w/-_wumpusADIDAS.osk/file'>
                    <img className='elohere-gif'src='https://be.s-ul.eu/ciSRySS5'></img>
                    <p className='elohere-title'>wumpusADIDAS</p> 
                    </a>
                </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Resha;
