import React, { useState, useEffect } from 'react';
import { Skins, MainPage, Delta, Speed, Anton, Social, About, Resha, Saraune } from './components';
import Error404 from './notFound'; // Corrija a importação para 'default'
import { Routes, Route } from 'react-router-dom';

function App() {
  const [darkMode, setDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('darkMode');
    return savedMode ? JSON.parse(savedMode) : false;
  });

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
    document.documentElement.classList.toggle('dark-mode', darkMode);
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <div>
      <button onClick={toggleDarkMode} className="dark-mode-toggle">
        {darkMode ? 'turn the lights on!' : 'turn the lights off!'}
      </button>
      <Routes>
        <Route path={'/'} element={<MainPage />} />
        <Route path={'/skins'} element={<Skins />} />
        <Route path={'/delta'} element={<Delta />} />
        <Route path={'/speed'} element={<Speed />} />
        <Route path={'/anton'} element={<Anton />} />
        <Route path={'/socials'} element={<Social />} />
        <Route path={'/aboutme'} element={<About />} />
        <Route path={'/resha'} element={<Resha />} />
        <Route path={'/saraune'} element={<Saraune />} />
        <Route path="*" element={<Error404 darkMode={darkMode} />} />
      </Routes>
    </div>
  );
}

export default App;