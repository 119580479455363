import React, { useState, useEffect } from 'react';
import '../Skins.css';
import { Link } from 'react-router-dom';

function Saraune() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    document.title = "saraune! skinhub";
  }, []);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div>
      <div className='skins-bg'>
        <h1 className='skins-title'>
          <a className='skins-link' href='https://osu.ppy.sh/users/10354591'>
          saraune <img className='avatar' src='http://s.ppy.sh/a/10354591?18' alt='avatar'></img>
          </a>
          <button 
            className={`toggle-button ${isVisible ? 'rotate' : ''}`} 
            onClick={toggleVisibility}
          >
            ↓
          </button>
        </h1>
        <div className={`skins-container ${isVisible ? 'expanded' : ''}`}>
        <div className='skins'>
                <div className='elohere_be'>
                    <a href='https://www.mediafire.com/file/sxvracrjdpm6c4j/boom_0524_edit.osk/file'>
                    <img className='elohere-gif'src='https://be.s-ul.eu/w3agmODf'></img>
                    <p className='elohere-title'>boom 0524 edit</p> 
                    </a>
                </div>
                <div className='elohere_be'>
                    <a href='https://www.mediafire.com/file/7v3sr2eyjbp2mvt/fukari_alt_edit.osk/file'>
                    <img className='elohere-gif'src='https://be.s-ul.eu/FKXrttLZ'></img>
                    <p className='elohere-title'>fukari alt edit</p> 
                    </a>
                </div>
                <div className='elohere_be'>
                    <a href='https://www.mediafire.com/file/48d4baap3vu326a/Kantoku_edit.osk/file'>
                    <img className='elohere-gif'src='https://be.s-ul.eu/xIpHJcNl'></img>
                    <p className='elohere-title'>Kantoku edit</p> 
                    </a>
                </div>
          </div>
          <div className='skins'>
                <div className='elohere_be'>
                    <a href='https://www.mediafire.com/file/gocyauedyw7lssw/roria%252Bsaraune.osk/file'>
                    <img className='elohere-gif'src='https://be.s-ul.eu/2OBcuRuq'></img>
                    <p className='elohere-title'>roria saraune</p> 
                    </a>
                </div>
                <div className='elohere_be'>
                    <a href='https://www.mediafire.com/file/060y96uzif5woew/Saraune_Leaves_%2528irdios%2529.osk/file'>
                    <img className='elohere-gif'src='https://be.s-ul.eu/CYPza5Mz'></img>
                    <p className='elohere-title'>Saraune Leaves (irdios)</p> 
                    </a>
                </div>
                <div className='elohere_be'>
                    <a href='https://www.mediafire.com/file/nmay97w5wmaj6sy/Saraune_Leaves.osk/file'>
                    <img className='elohere-gif'src='https://be.s-ul.eu/BrlJmR9v'></img>
                    <p className='elohere-title'>Saraune Leaves</p> 
                    </a>
                </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default Saraune;
