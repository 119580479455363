import Skins from './Skins';
import MainPage from './MainPage';
import Delta from './players/delta';
import Speed from './players/speed';
import Anton from './players/anton';
import Social from './Socials';
import About from './About';
import Resha from './players/resha';
import Saraune from './players/saraune';


export {
  Skins,
  MainPage,
  Delta,
  Speed,
  Anton,
  Social,
  About,
  Resha,
  Saraune
};