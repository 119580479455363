import React from 'react';
import { useEffect } from 'react';
import './Skins.css';
import Delta from './players/delta';
import Speed from './players/speed';
import Anton from './players/anton';
import Resha from './players/resha';
import Saraune from './players/saraune';
import { Link } from 'react-router-dom';

function Skins() {
    useEffect(() => {
        document.title = "skinhub!";
    }, []);
    return (
        <div style={{ paddingBottom: '50px' }}>
            <Speed />
            <Delta />
            <Anton />
            <Resha />
            <Saraune />
            <footer className="footer">
                <Link to="/">get me back to home!</Link>
            </footer>
        </div>
    );
}
export default Skins;