import React, { useState, useEffect } from 'react';
import '../Skins.css';
import { Link } from 'react-router-dom';

function Anton() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    document.title = "anton! skinhub";
  }, []);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div>
      <div className='skins-bg'>
        <h1 className='skins-title'>
          <a className='skins-link' href='https://osu.ppy.sh/users/12958376'>
            anton <img className='avatar' src='http://s.ppy.sh/a/12958376?206' alt='avatar'></img>
          </a>
          <button 
            className={`toggle-button ${isVisible ? 'rotate' : ''}`} 
            onClick={toggleVisibility}
          >
            ↓
          </button>
        </h1>
        <div className={`skins-container ${isVisible ? 'expanded' : ''}`}>
          <div className='skins'>
            <div className='elohere_be'>
              <a href='https://www.mediafire.com/file/syw4vygjmnbnvim/god_ordered_me.osk/file'>
                <img className='elohere-gif' src='https://be.s-ul.eu/Lrr4CHMG' alt='god ordered me'></img>
                <p className='elohere-title'>god ordered me</p>
              </a>
            </div>
            <div className='elohere_be'>
              <a href='https://www.mediafire.com/file/28jukaq64mja8og/bbbbbbbsd22S.osk/file'>
                <img className='elohere-gif' src='https://be.s-ul.eu/ktngOzTd' alt='bbbbbbbsd22S'></img>
                <p className='elohere-title'>bbbbbbbsd22S</p>
              </a>
            </div>
            <div className='elohere_be'>
              <a href='https://www.mediafire.com/file/ev0shfr9co2l92j/ll.osk/file'>
                <img className='elohere-gif' src='https://be.s-ul.eu/yBKJyXhR' alt='ll'></img>
                <p className='elohere-title'>ll</p>
              </a>
            </div>
            <div className='elohere_be'>
              <a href='https://www.mediafire.com/file/cn37n47k67rf1i1/kune_obtio_mash.osk/file'>
                <img className='elohere-gif' src='https://be.s-ul.eu/go4RSXeb' alt='kune obtio mash'></img>
                <p className='elohere-title'>kune obtio mash</p>
              </a>
            </div>
          </div>
          <div className='skins'>
            <div className='elohere_be'>
              <a href='https://www.mediafire.com/file/sqe8njl0h4mrfmp/chai_tea_%2526_Soup_Time_kys_mate.S.osk/file'>
                <img className='elohere-gif' src='https://be.s-ul.eu/GWTApieD' alt='chai tea & Soup Time kys mate.S'></img>
                <p className='elohere-title'>chai tea & Soup Time kys mate.S</p>
              </a>
            </div>
            <div className='elohere_be'>
              <a href='https://www.mediafire.com/file/8fazd66ubuhvpmq/WWW_2.01_aaa.osk/file'>
                <img className='elohere-gif' src='https://be.s-ul.eu/AZ6wTu0d' alt='WWW 2.01 aaa'></img>
                <p className='elohere-title'>WWW 2.01 aaa</p>
              </a>
            </div>
            <div className='elohere_be'>
              <a href='https://www.mediafire.com/file/jfdq4vj8dbxq6zg/amanogawa_saya_rafis_miau.osk/file'>
                <img className='elohere-gif' src='https://be.s-ul.eu/m0SSZSzt' alt='amanogawa saya rafis miau'></img>
                <p className='elohere-title'>amanogawa saya rafis miau</p>
              </a>
            </div>
            <div className='elohere_be'>
              <a href='https://www.mediafire.com/file/sngzz44x0ozcsce/GATE_OF_STEINER.osk/file'>
                <img className='elohere-gif' src='https://be.s-ul.eu/f73gpXSj' alt='GATE OF STEINER'></img>
                <p className='elohere-title'>GATE OF STEINER</p>
              </a>
            </div>
          </div>
          <div className='skins'>
            <div className='elohere_be'>
              <a href='https://www.mediafire.com/file/2kesa5eksts5zvz/qishilu1212.osk/file'>
                <img className='elohere-gif' src='https://be.s-ul.eu/Bg7NTs5o' alt='qishilu1212'></img>
                <p className='elohere-title'>qishilu1212</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Anton;
