import { useState } from "react";
import { motion } from "framer-motion";
import './App.css';

const Error404 = ({ darkMode }) => {
  const [broken, setBroken] = useState(false);

  return (
    <div className={darkMode ? 'dark-mode' : ''} style={{ textAlign: "center", padding: "50px", height: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
      <div style={{ display: "flex" }} onClick={() => setBroken(true)}>
        <motion.h1
          style={{ fontSize: "6rem", fontWeight: "bold", color: "var(--text-color)", margin: "0 5px" }}
          animate={broken ? { x: -50, rotate: -10, opacity: 0.8 } : { x: 0 }}
          transition={{ duration: 0.5 }}
        >
          4
        </motion.h1>

        <motion.h1
          style={{ fontSize: "6rem", fontWeight: "bold", color: "var(--text-color)", margin: "0 5px" }}
          animate={broken ? { y: 20, rotate: 15, opacity: 0.8 } : { y: 0 }}
          transition={{ duration: 0.5 }}
        >
          0
        </motion.h1>

        <motion.h1
          style={{ fontSize: "6rem", fontWeight: "bold", color: "var(--text-color)", margin: "0 5px" }}
          animate={broken ? { x: 50, rotate: 10, opacity: 0.8 } : { x: 0 }}
          transition={{ duration: 0.5 }}
        >
          4
        </motion.h1>
      </div>

      <p style={{ color: "var(--text-color)" }}>this page isnt even real bro</p>
      <a href="/" style={{ color: "var(--text-color)", textDecoration: "underline" }}>get back!</a>
    </div>
  );
};

export default Error404;
