import '../App.css';
import React from 'react';
import { useState } from "react";
import { LastFmData } from '../Lastfm';
import { Link } from 'react-router-dom';



function MainPage() {

  const [openModal, setOpenModal] = useState(false);


  return (
    <div className="wrapper">
      {openModal && <div className="modal-background"></div>}
      
      <div className="header">
        <div className="wrapper">
         <h1 className='typing-demo'> welcome!</h1>
        </div>
       
      <div  className='info'>
      <Link to="/aboutme "><a className='link-socials'>about me</a></Link>
      <br></br>
        <Link to="/socials "><a className='link-socials'>socials</a></Link>
        <br></br>
        <Link to="/skins "><a className='osu-skins'>osu! skinhub</a></Link>
      </div>

    
    
    <div className='lastscrobble'>
      <LastFmData
      userName={'beernardoo'}
      apiKey={process.env.REACT_APP_API_KEY}
      />
    </div>
    </div>
      </div>
  );
}

export default MainPage;
