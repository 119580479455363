import '../App.css';
import { useEffect } from 'react';
import React from 'react';
import { useState } from "react";
import { LastFmData } from '../Lastfm';
import { Link } from 'react-router-dom';



function Social() {
useEffect(() => {
        document.title = "be | socials!";
      }, []);

  return (
      
      <div className="header">
        <div  className='info'>
                <Link to="/aboutme "><a className='link-socials'>about me</a></Link>
                <br />
                <Link to="/"><a className='link-socials'>home</a></Link>
                <br></br>
                <Link to="/skins "><a className='osu-skins'>osu! skinhub</a></Link>
              </div>
        <div className="wrap-2">
         <h1 className='socials-text'> you can find me in these other places!</h1>
        </div>
        <div  className='info-socials'>
        <a className='osu-profile' href='https://old.ppy.sh/u/speed'>osu!</a>
        <br></br>
        <a className='steam-profile' href='https://steamcommunity.com/id/b9r-/'>steam</a>
        <br></br>
        <a className='github-profile' href='https://github.com/b9rnardo'>github</a>
        <br></br>
        <a className='twitch-profile' href='https://www.twitch.tv/b9rnardo'>twitch</a> 
        <br></br>
        <a className='lastfm-profile' href='https://www.last.fm/user/beernardoo'>last.fm</a>
        <br></br>
        <a className='rym-profile' href='https://rateyourmusic.com/~b9rnardo'>rym</a>
        <br></br>
      </div>

    <div className='lastscrobble'>
      <LastFmData
      userName={'beernardoo'}
      apiKey={process.env.REACT_APP_API_KEY}
      />
    </div>
    </div>
  );
}

export default Social;
