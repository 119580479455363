import '../App.css';
import { useEffect } from 'react';
import React from 'react';
import { useState } from "react";
import { LastFmData } from '../Lastfm';
import { Link } from 'react-router-dom';

function About() {
  useEffect(() => {
    document.title = "be | about me!";
  }, []);

  return (
    <div className="header">
      <div className='info'>
      <div className='info'>
        <Link to="/" className='link-socials'>home</Link>
        <br />
        <Link to="/socials "><a className='link-socials'>socials</a></Link>
        <br />
        <Link to="/skins" className='osu-skins'>osu! skinhub</Link>
      </div>
        <div className="wrap-3">
          <h1 className='aboutme-header'> about me!</h1>
          <p className='aboutme-text'>
            hey, i'm be, or hazard, or speed, whatever you wanna call me.
            i'm 20, brazilian, and i've been playing competitive games since i was a kid, mainly fps.
            since 2018, i got obsessed with having good aim in games, which led me to download osu!, a rhythm game that introduced me to amazing people and helped me develop new skills and passions in a way no other game ever did.
            besides gaming, i'm currently pursuing a degree in information systems, i love experimental music, and i speak three languages: german, portuguese, and english (not fully fluent in german tho).
          </p>
        </div>
      </div>
      <div className='lastscrobble'>
        <LastFmData
          userName={'beernardoo'}
          apiKey={process.env.REACT_APP_API_KEY}
        />
      </div>
    </div>
  );
}

export default About;
